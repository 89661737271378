import React from 'react';

export default function SocialLink(props) {
  return (
    <li className="alfa-footer-socials__item">
      <a
        className={props.classlink}
        href={props.link}
        aria-label="TradingView on Twitter"
        rel="noreferrer"
        target="_blank"
      >
        {props.image}{' '}
      </a>
    </li>
  );
}
