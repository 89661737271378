import React from 'react';
import './BlueBtn.css';
import {ReactComponent as Arrow} from './assets/arrow.svg';
import {useDispatch} from 'react-redux';

export default function BlueBtn(props) {
  const dispatch = useDispatch();
  return (
    <div className="indent_btn">
      <a
        className="btn_demo"
        href={props.link}
        onClick={() => {
          props.click && dispatch({type: 'SHOWBANNER', payload: true});
        }}
      >
        <div className="btn_text">{props.btn_text}</div> <Arrow className="btn_arrow" />
      </a>
    </div>
  );
}
