import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.css';
import {Provider, useSelector} from 'react-redux';
import createStore from './core/store';
import CheckCollapse from './components/CheckCollapse/CheckCollapse.jsx';
import Banner from './components/Banner/banner';
import {HelmetProvider} from 'react-helmet-async';
import {hydrate, render} from 'react-dom';

const store = createStore();
const App = () => {
  const showBanner = useSelector((state) => state.showBanner);
  const changeTheme = useSelector((state) => state.changeTheme);
  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <div className="collapsedPage" data-theme={changeTheme}>
        <CheckCollapse />
        {showBanner && <Banner />}
      </div>
    </HelmetProvider>
  );
};

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      {' '}
      <BrowserRouter>
        {' '}
        <App />{' '}
      </BrowserRouter>{' '}
    </Provider>,
    rootElement,
  );
} else {
  render(
    <Provider store={store}>
      {' '}
      <BrowserRouter>
        {' '}
        <App />{' '}
      </BrowserRouter>{' '}
    </Provider>,
    rootElement,
  );
}
