import React from 'react';
import './crmMain.css';

import {ReactComponent as Control} from './assets/controlUser.svg';
import {ReactComponent as Chart} from './assets/chart.svg';
import {ReactComponent as Frame} from './assets/framework.svg';
import {ReactComponent as News} from './assets/newspaper.svg';
import {ReactComponent as Programming} from './assets/programming.svg';
import {ReactComponent as Phone} from './assets/ipPhone.svg';

export default function CrmMain() {
  let arrBenefit = [
    {
      title: 'Control your users',
      text: 'Get information about clients of specific departments',
      ico: <Control />,
    },
    {
      title: 'Activity monitoring',
      text: 'Track the balances of all client accounts and view all transactions',
      ico: <Chart />,
    },
    {
      title: 'Compliance setting',
      text: 'Manage your KYC system. Check the uploaded documents',
      ico: <Frame />,
    },
    {
      title: 'Internal calendar',
      text: 'Schedule general calls and meetings for specific departments',
      ico: <News />,
    },
    {
      title: 'IB Programs',
      text: 'Set up your own IB programs to attract new clients',
      ico: <Programming />,
    },
    {
      title: 'IP Telephony',
      text: 'Make calls directly from the CRM and store records of customers conversation',
      ico: <Phone />,
    },
  ];
  return (
    <div className="crm_mainPage">
      <h2 className="crm_mainPage-subTitle sub_title light-blue-gradient">
        Convenient tools for managing your business
      </h2>
      <p className="main_text">
        Control the entire process from the registration step using a wide range of functions in the
        CRM system
      </p>
      <div className="indent_alfa_block">
        <div className="crm__page_alfa-block4">
          <div className="content_features_copy">
            {arrBenefit.map((i, index) => (
              <li className="crm_list-wrapper" key={index}>
                {i.ico}
                <h3 className="crm_list-title">{i.title}</h3>
                <p className="why_text">{i.text}</p>
              </li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
