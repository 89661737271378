import React from 'react';
import './privacyPage.scss';
import SEO from '../../components/SEO/SEO';
import imgLegal from './assets/Legal documents.png';

export default function PrivacyPage() {
  return (
    <div className="privacyPage">
      <SEO
        title="ScaleTrade privacy policy"
        description="Legal documentation."
        name="ScaleTrade"
        type="article"
        link="/privacy-policy"
        img={imgLegal}
      />

      <div className="titleBlock">
        <h2 className="title light-blue-gradient">PRIVACY POLICY</h2>
        <p className="main_text">
          We are committed to protect your Personal Data and respect your privacy. This privacy
          policy sets out the basis on which any personal data we collect from you, or that you
          provide to us, will be processed by us and what choices you have about your personal data.
          Please read the following carefully to understand our views and practices regarding your
          personal data and how we will treat it.
        </p>
      </div>
      <div className="info_side">
        <h2 className="sub_title light-gradient">1. COLLECTION OF PERSONAL INFORMATION</h2>
        <p className="main_text">
          Each of Users may visit public sections of the Website without disclosing his/her name or
          any Information or Personal data; therefore, all Users who use the public sections of the
          Website may remain anonymous unless they decide to register on the Website. While the User
          registers on the Website, the Company may request the Users to provide information, which
          are necessary for the and use of Website. This information shall be included into the
          User's account through which he/she uses the Website.
        </p>
        <p className="main_text">
          The Company shall be entitled to collect the following information about the Users:
        </p>
        <ul className="list">
          <li>
            <p className="main_text">e-mail address;</p>
          </li>
          <li>
            <p className="main_text">phone number;</p>
          </li>
          <li>
            <p className="main_text">full name;</p>
          </li>
          <li>
            <p className="main_text">
              links and nicknames in the following social platforms and messengers: Facebook,
              Twitter, Telegram, Skype etc
            </p>
          </li>
        </ul>
      </div>
      <div className="info_side">
        <h2 className="sub_title light-gradient">2. STORING AND PROTECTING YOUR INFORMATION</h2>
        <p className="main_text">
          The information discussed in this privacy policy is limited to personal information that
          may be used to identify a specific person. This differs from anonymous information which
          cannot in any way be used to identify you. All the information we store that may in any
          way be used to identify you is treated with the greatest degree of caution. We store all
          client information in secure environments. We comply with all regional and international
          laws in terms of the safeguarding all of our client privacy and information. We restrict
          access to all of our infrastructure both physical and online. We authorize access to
          client information only to staff who require access to fulfill their job responsibilities.
          All staff with access to sensitive information are monitored and properly trained to
          ensure compliance with regulations. We securely store all client information throughout
          the duration of your use of ScaleTrade website as well as for up to five years afterward
          in order to comply with all laws and resolve any disputes regarding transaction history.
        </p>
      </div>
      <div className="info_side">
        <h2 className="sub_title light-gradient">3. USE OF INFORMATION</h2>
        <p>
          The information we collect is primarily intended to facilitate account access and provide
          smooth functionality of the site and its features. The ways in which we make use of the
          information may include:
        </p>
        <ul className="info_side">
          <li>
            <p className="main_text">to provide site functions and customer service</p>
          </li>
          <li>
            <p className="main_text">
              to measure the effectiveness of our design, layout, and site functionality
            </p>
          </li>
          <li>
            <p className="main_text">
              to provide targeted services, marketing, and offers specific to your site uses and
              interests
            </p>
          </li>
        </ul>
        <p className="main_text">
          The Company may use e-mail addresses for sending Website news or updates. The User may
          unsubscribe from such e-mails at any time.
        </p>
        <p className="main_text">
          The Company does not sell any personal data of the Users to third parties. The Company has
          the right to hire authorized contractors for the purpose of compliance with this Policy or
          other internal documents. The Company may disclose the User's personal data only to
          enforce and comply with the law.
        </p>
      </div>
      <div className="info_side">
        <h2 className="sub_title light-gradient">4. INFORMATION SECURITY</h2>
        <p className="main_text">
          Immediate access to personal data of the Users is allowed only to the Administration. The
          Administration shall strictly keep confidentiality of and prevent unauthorized third party
          access to personal data. Special measures are implemented in the Website to ensure safety
          and protection of the Users' personal data from loss, misuse, unauthorized access, use,
          disclosure, amendment or destruction.
        </p>
        <p className="main_text">
          THE COMPANY REMINDS THAT NONE OF THE EXISTING DATA TRANSMISSION METHODS CAN BE ABSOLUTELY
          SAFE. THEREFORE, DESPITE ALL SAFETY MEASURES IMPLEMENTED, THE COMPANY CANNOT FULLY
          GUARANTEE INTEGRITY AND SAFETY OF THE INFORMATION AND DATA.
        </p>
        <p className="main_text">
          The Company shall not be responsible for unlawful acts of third parties, hackers,
          intruders, and other offenders of applicable legislation that can violate provisions of
          this Privacy Policy and try to collect, either fully or in part, personal data of the
          Users and to use it for personal advantages.
        </p>
      </div>
      <div className="info_side">
        <h2 className="sub_title light-gradient">5. USE OF COOKIES</h2>
        <p className="main_text">
          The Company shall use cookies, web beacons, unique identifiers, and similar technologies
          to collect information about the pages the User surfed, the links the User clicked, and
          other actions of the User related to visiting and using of the Website.
        </p>
        <p className="main_text">
          The Company shall have the right to use cookies, web beacons, unique identifiers, and
          similar technologies for storing information to make using of the Website more efficient,
          fast, and safe, to improve the functioning of the Website, as well as to provide
          advertising adapted to the interests of the User.
        </p>
        <p className="main_text">
          The User may block, delete, or disable these technologies if his/her web-browser or device
          allows this.
        </p>
        <p className="main_text">
          Use of the website constitutes consent to our use of cookies. Cookies are micro data files
          stored on your computer in order to identify you and your past browsing information. They
          are used by most websites. We use cookies in order to:
        </p>
        <ul className="list">
          <li>
            <p className="main_text">Recognize you as a ScaleTrade user</p>
          </li>
          <li>
            <p className="main_text">Customize the content you see</p>
          </li>
          <li>
            <p className="main_text">Track the effectiveness of affiliate or marketing campaigns</p>
          </li>
          <li>
            <p className="main_text">
              Collect basic information about your computer, device, and browser in order to prevent
              security
            </p>
          </li>
          <li>
            <p className="main_text">Breaches and improve functionality of the website</p>
          </li>
        </ul>
        <p className="main_text">
          We use both "session" and "persistent" cookies in order to effectively achieve the above
          results. "Session" cookies are used to improve the website function within a single
          session, "persistent" cookies stay on your computer after your logout in order to remember
          your preferences next time you visit.
        </p>
        <p className="main_text">
          All of the cookies we collect are encoded so that no third-party can intercept or use any
          of the information collected in them. You can always opt out of the use of cookies on our
          site using your browser's cookie prevention add-ons or plugins.
        </p>
      </div>
      <div className="info_side">
        <h2 className="sub_title light-gradient">6. AMENDMENTS</h2>
        <p className="main_text">
          The Company retains the right to update, edit, and amend this Policy without additional
          notice to the Users. Such amendments shall take effect on the date of publication of the
          updated version of the Policy on the Website unless otherwise provided by the amendments.
          If you have a question or complaint about this Policy, please send an email to
          support@scaletrade.io
        </p>
      </div>
    </div>
  );
}
