import React from 'react';

export default function Monitor(props) {
  return (
    <div className="monitors-picture" style={props.stuleMonitor}>
      <div>
        <picture className="picture">
          <img src={props.monitorlink} alt="desktop-monitors"></img>
        </picture>
      </div>
    </div>
  );
}
