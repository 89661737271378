import React from 'react';
import './crmTitle.css';
import BlueBtn from '../../../../components/BlueBtn/BlueBtn';
import useWidth from '../../../../actions/getWidth';

export default function CrmTitle() {
  let width = useWidth();
  let CrmTitle_img;
  if (width >= 768) {
    CrmTitle_img = require('./assets/CRM page 1.webp');
  }
  return (
    <div className="crm__Page container">
      <h1 className="title light-blue-gradient"> ScaleTrade CRM</h1>
      <p className="crm__Page-text main_text">
        Track registrations, orders, deposits, withdrawals and accounts with our CRM. Manage your
        entire brokerage business. Optimize your customer flow and convert more leads into traders
        by automating your process
      </p>
      <div className="two_sides row">
        <div className="img_box col-lg-6">
          <img src={CrmTitle_img} alt="ScaleTrade CRM image" className="crm_img_type" />
        </div>
        <div className="col-lg-6">
          <ul className="crm__Page-list main_text">
            <li>Manage all activities and accounts of your customers in one place</li>
            <li>Deposit and withdrawal management</li>
            <li>Access to various reports</li>
            <li>Check user’s activity on the platform. </li>
            <li>View customer transactions, account information and more</li>
          </ul>
          <BlueBtn btn_text="Contact Us" click={true} />
        </div>
      </div>
    </div>
  );
}
