import React from 'react';
import './NotFoundPage.scss';

export default function NotFoundPage() {
  return (
    <div className="NotFoundPage">
      <h1 className="NotFoundPageTitle light-blue-gradient">404</h1>
      <h2 className="title light-blue-gradient">Page not found</h2>
      <p className="main_text">
        The page you are looking for may have benn moved, deleted, or possibly never existed.
      </p>
    </div>
  );
}
