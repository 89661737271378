import React, {useState} from 'react';
import './pricesPage.scss';
import Subscription from './components/subscription/subscription.jsx';
import check from './asset/checkmark.svg';
import SEO from '../../components/SEO/SEO';
import imgPrice from './asset/Competitive price modules.png';

export default function PricesPage() {
  return (
    <>
      <SEO
        title="ScaleTrade Subscription"
        description="Large selection of possible subscriptions. Competitive prices, clear description of each paid module. Wide selection of additional tools and modules to suit any budget."
        name="ScaleTrade"
        type="article"
        link="/prices"
        img={imgPrice}
      />
      <div className="infoBlock">
        <div className="tabsInfo">
          <Subscription />
        </div>
      </div>
    </>
  );
}
