import React from 'react';
import './AboutPage.css';
import WallOfLove from './components/WallOfLove/WallOfLove.jsx';
import CardItem from './components/CardsItem/Cardsitem.jsx';
import AthleteCard from './components/AthleteCard/AthleteCard.jsx';
import tablet from './assets/table-opt.webp';
import centerimage from './assets/phone3-opt.webp';
import appimage from './assets/rimage-1.webp';

import {ReactComponent as StarIco} from './assets/star.svg';
import {ReactComponent as CardIco1} from './assets/cardico1.svg';
import {ReactComponent as CardIco2} from './assets/cardico2.svg';
import {ReactComponent as CardIco3} from './assets/cardico3.svg';
import BlueBtn from '../../components/BlueBtn/BlueBtn';
import SEO from '../../components/SEO/SEO';
import imgAbout from './assets/Technology providers for brokers.png';
import LowestBlock from '../NewHomePage/components/lowestBlock/lowestBlock';

export default function AboutPage() {
  let cardarr = [
    {
      CardIco: <CardIco1 />,
      CardTitle1: 'Accessibility',
      CardTitle2: 'and clarity',
      CardText:
        'We allow the broker and client to have a close relationship and be accessible and open to each other.',
    },
    {
      CardIco: <CardIco2 />,
      CardTitle1: 'Creating a ',
      CardTitle2: ' business-ecosystem',
      CardText:
        'We are not creating just a trading platform, we are creating an environment - we are creating a cool system!',
    },
    {
      CardIco: <CardIco3 />,
      CardTitle1: 'Trading',
      CardTitle2: 'should be social',
      CardText:
        'Open discussion of the product and the opportunity to express yourself are the best keys to understanding further development.',
    },
  ];
  let athletearr = [
    {
      CardClassName: 'emmanuelCard',
      AthleteName: 'Emmanuel Egbuna',
      AthleteLink: '#',
      AthleteCaption: 'Sales manager',
    },
    {
      CardClassName: 'caiteCard',
      AthleteName: 'Vladislav Savchuk',
      AthleteLink: '#',
      AthleteCaption: 'Sales officer',
    },
    {
      CardClassName: 'leoCard',
      AthleteName: 'Taylor Trevor Bingwa',
      AthleteLink: '#',
      AthleteCaption: 'VIP Relationship Manager',
    },
  ];
  return (
    <>
      <SEO
        title="About ScaleTrade"
        description="ScaleTrade technology provider which makes it possible to launch your own broker in the shortest time. Ready white label solution with the customization options to suit your business needs."
        name="ScaleTrade"
        type="article"
        link="/about"
        img={imgAbout}
      />
      <div className="alfa-about">
        <div className="wallOfLove">
          <h1 className="title light-blue-gradient sub_title-gradient">Our life</h1>
          <WallOfLove />
        </div>
        <div className="textBlock">
          <p className="sub_title light-blue-gradient">Our achievements</p>
        </div>
        <div className="content">
          <div className="factsWrapper">
            <div>
              <div className="factsBoxTitle">10m+</div>
              <p className="factsBoxContent">Traders and investors who work with our platform</p>
            </div>
            <div>
              <div className="factsBoxTitle">30+ </div>
              <p className="factsBoxContent">
                Published applications in the App Store and Google play
              </p>
            </div>
            <div>
              <div className="factsBoxTitle">
                <span>
                  <StarIco />
                </span>
                <p>4.5</p>
              </div>
              <p className="factsBoxContent">
                <a href="/" rel={'noreferrer'} target="_blank" className="colorLink">
                  Finance app-wise
                </a>
                , no one else is more loved.
              </p>
            </div>
            <div>
              <div className="factsBoxTitle">100M+</div>
              <p className="factsBoxContent">Open trades through our platform</p>
            </div>
          </div>
        </div>
        <div className="codeOfHonor">
          <h2 className="sub_title light-blue-gradient sub_title-gradient">
            Our mission and our goals
          </h2>
          <p className="codeOfHonorDescription">
            To live up to that mission, we’ve made three principles that all our teams swear by:
          </p>
        </div>
        <div className="cards">
          <div className="cardsWrapper">
            {cardarr.map((i, index) => (
              <CardItem
                CardIco={i.CardIco}
                CardTitle1={i.CardTitle1}
                CardTitle2={i.CardTitle2}
                CardText={i.CardText}
                key={index}
              />
            ))}{' '}
          </div>
        </div>
        <div className="builtOfRisk">
          <h2 className="sub_title light-blue-gradient sub_title-gradient">Our leaders</h2>
          <p className="builtOfRiskDescription">
            Our team consists of first-class industry professionals, experienced software developers
            and top salespeople who share our common goal of producing a cool product that covers
            most of the needs of the trading market.
          </p>
        </div>
        <div className="athleteCards">
          <div className="athleteCardsWrapper arutyunPos">
            <div></div>
            <div>
              <div className="arutyunCard"> </div>
              <p className="athleteCardDescription">Arutyun Iskandaryan</p>
              <p className="athleteCardLink">
                <a href="#" rel={'noreferrer'} target="_blank" className="colorLink">
                  Board member
                </a>
              </p>
            </div>
          </div>
          <div className="athleteCardsWrapper">
            {athletearr.map((i, index) => (
              <AthleteCard
                CardClassName={i.CardClassName}
                AthleteName={i.AthleteName}
                AthleteLink={i.AthleteLink}
                AthleteCaption={i.AthleteCaption}
                key={index}
              />
            ))}{' '}
          </div>
        </div>
        <LowestBlock />
      </div>
    </>
  );
}
