import {useEffect, useState} from 'react';

export default function useWidthHook() {
  const handleResize = () => {
    setWindowWidth(window.outerWidth);
  };

  let [windowWidth, setWindowWidth] = useState(null);
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}
