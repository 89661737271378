import React from 'react';
import BlueBtn from '../BlueBtn/BlueBtn';

import './TwoSides.css';

export default function TwoSides(props) {
  return (
    <section className={`container two_sides_indent ${props.last && 'two_sides_last'}`}>
      {props.side === 'left' ? (
        <div className="two_sides row">
          <div className="img_box col-lg-6">
            <img
              src={props.img}
              alt={props.alt}
              className={`img_type ${props.side === 'left' && 'img_left'}`}
            />
          </div>
          <div className="col-lg-6">
            <h2 className="sub_title sub_title-gradient light-blue-gradient">{props.title}</h2>
            <p className="main_text">{props.main_text}</p>
            <ul className="main_text list_type">
              {props.list_title && <p className="main_text">{props.list_title}</p>}
              {props.list_arr && props.list_arr.map((i, index) => <li key={index}>{i.item}</li>)}
            </ul>
            {props.text_btn && <BlueBtn btn_text={props.text_btn} link={props.link} />}
          </div>
        </div>
      ) : (
        <div className="two_sides row">
          <div className="col-lg-6">
            <h2 className="sub_title sub_title-gradient light-blue-gradient ">{props.title}</h2>
            <p className="main_text">{props.main_text}</p>
            <ul className="main_text list_type">
              {props.list_title && <p className="main_text">{props.list_title}</p>}
              {props.list_arr && props.list_arr.map((i, index) => <li key={index}>{i.item}</li>)}
            </ul>
            {props.text_btn && <BlueBtn btn_text={props.text_btn} link={props.link} />}
          </div>
          <div className="img_box col-lg-6">
            <img
              src={props.img}
              alt={props.alt}
              className={`img_type ${props.side === 'right' && 'img_right'}`}
            />
          </div>
        </div>
      )}
    </section>
  );
}
