import React, {lazy, Suspense} from 'react';
import NavHead from '../Header/Header.jsx';
import Footer from '../Footer/Footer.jsx';
import AboutPage from '../../Pages/About/AboutPage.jsx';
import HomePage from '../../Pages/HomePage/HomeContent';
import PromoPage from '../../Pages/Promo/PromoContent';
import {Routes, Route} from 'react-router-dom';
import NewHomePage from '../../Pages/NewHomePage/newHomePage.jsx';
import CrmPage from '../../Pages/CrmPage/crmPage.jsx';
import WhitePage from '../../Pages/WhiteLabel/WhiteLabel.jsx';
import PricesPage from '../../Pages/PricesPage/pricesPage.jsx';
import FiderPage from '../../Pages/FiderPage/FiderPage.jsx';
import TermsPage from '../../Pages/TermsPage/terms&ConditionsPage.jsx';
import PrivacyPage from '../../Pages/PrivacyPage/privacyPage.jsx';
import CookiePage from '../../Pages/CookiePage/cookiePage.jsx';
import CopyTrading from '../../Pages/CopyTradingPage/CopyTrading.jsx';
import XproBanner from '../XproBanner/XproBanner.jsx';
import TechnologyStack from '../../Pages/TechnologyStack/TechnologyStack.jsx';
import NotFoundPage from '../../Pages/NotFoundPage/NotFoundPage.jsx';
import PropsTrading from '../../Pages/PropsPage/PropTrading.jsx';
import './check.css';

// const AboutPage = lazy(() => import('../../Pages/About/AboutPage.jsx'));
// const HomePage = lazy(() => import('../../Pages/HomePage/HomeContent'));
// const NewHomePage = lazy(() => import('../../Pages/NewHomePage/newHomePage.jsx'));
// const CrmPage = lazy(() => import('../../Pages/CrmPage/crmPage.jsx'));
// const PricesPage = lazy(() => import('../../Pages/PricesPage/pricesPage.jsx'));
// const FiderPage = lazy(() => import('../../Pages/FiderPage/FiderPage.jsx'));
// const PrivacyPage = lazy(() => import('../../Pages/PrivacyPage/privacyPage.jsx'));
// const CopyTrading = lazy(() => import('../../Pages/CopyTradingPage/CopyTrading.jsx'));
// const TechnologyStack = lazy(() => import('../../Pages/TechnologyStack/TechnologyStack.jsx'));
// const NotFoundPage = lazy(() => import('../../Pages/NotFoundPage/NotFoundPage.jsx'));
// const PropsTrading = lazy(() => import('../../Pages/PropsPage/PropTrading.jsx'));
// const  = lazy(() => import(''));

export default function CheckCollapse() {
  return (
    <>
      <XproBanner />
      <NavHead />
      <Routes>
        <Route path="/" element={<NewHomePage />} />
        <Route path="platform" element={<HomePage />} />
        <Route path="crm" element={<CrmPage />} />
        <Route path="copy-trading" element={<CopyTrading />} />
        <Route path="feeder" element={<FiderPage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="prices" element={<PricesPage />} />
        <Route path="privacy-policy" element={<PrivacyPage />} />
        <Route path="prop-trading" element={<PropsTrading />} />
        <Route path="technology-stack" element={<TechnologyStack />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </>
  );
}
