import React from 'react';
import './carditem.css';

export default function CardItem(props) {
  return (
    <div className="cardsItem cardsItem1">
      <span className="cardsIcon">{props.CardIco}</span>
      <h3 className="cardsTitle light-gradient">
        {props.CardTitle1}
        <br />
        {props.CardTitle2}
      </h3>
      <p className="cardsText">{props.CardText}</p>
    </div>
  );
}
