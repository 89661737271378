import React from 'react';
import BlueBtn from '../../components/BlueBtn/BlueBtn';
import TwoSides from '../../components/twoSidesBlock/TwoSides';
import LowestBlock from '../NewHomePage/components/lowestBlock/lowestBlock';
import './PropTrading.css';

import propsImg3 from './assets/PropImg3.png';
import propsImg2 from './assets/PropsImg2.png';
import propsImg from './assets/PropsImg1.png';
import {ReactComponent as AutoImg} from './assets/automation.svg';
import {ReactComponent as EaseImg} from './assets/ease.svg';
import {ReactComponent as ManagementImg} from './assets/management.svg';
import {ReactComponent as MasterImg} from './assets/masters.svg';
import {ReactComponent as StatsImg} from './assets/statistics.svg';
import {ReactComponent as TimeImg} from './assets/time.svg';
import SEO from '../../components/SEO/SEO';
import imgCopy from './assets/Copy expert traders.png';

export default function PropsTrading() {
  let arrCopy = [
    {
      title: 'Turnkey Solution for Prop Firms',
      main_text:
        'ScaleTrade, an award-winning trading platform is a comprehensive solution for launching and automating prop trading businesses. We offer our clients all-inclusive trading software that aligns with their prop company strategy and business goals. Discover the power of ScaleTrade for optimized prop trading operations and enhanced profitability.',
      img: propsImg,
      alt: 'Turnkey Solution for Prop Firms',
      side: 'right',
    },
    {
      title: 'Tailored to your business',
      main_text:
        'Our trading platform offers maximum flexibility and is constantly developed based on market research and inquiries from potential clients. We provide a wide range of APIs that allow our clients to easily integrate every application to create a complete trading ecosystem tailored to their specific requirements. ScaleTrade provides expansive branding options that empower brokers to establish a strong market presence.',
      img: propsImg2,
      alt: 'Tailored to your business',
      side: 'left',
    },
    {
      title: 'Innovative Software for Proprietary Trading Excellence',
      main_text:
        'ScaleTrade is at the forefront of revolutionary technology, offering a sophisticated platform designed to elevate prop trading companies to new heights. Developed with state-of-the-art progressive web app technology, our platform redefines the way prop trading firms operate and empowers them to deliver an unparalleled trading experience to their traders.',
      img: propsImg3,
      side: 'right',
      alt: 'Innovative Software for Proprietary Trading Excellence',
      last: true,
    },
  ];

  let arrFeatures = [
    {
      ico: <EaseImg />,
      title: 'High Capacity Server',
      main_text:
        'The ScaleTrade platform is based on a high-performance server in order to guarantee the best efficiency available for the trading environment. It is possible to have 200k accounts without any performance loss',
    },
    {
      ico: <StatsImg />,
      title: 'API connectivity',
      main_text:
        'ScaleTrade provides access to various APIs in order to enable the easy integration and smooth operation of prop trading companies’ systems. Our APIs provide the flexibility to tailor trading systems according to your requirements',
    },
    {
      ico: <AutoImg />,
      title: 'Prop Trading Management',
      main_text:
        'ScaleTrade provides various tools which help to manage the funded accounts (also, set evaluations, challenges, and rules to fit your exact specifications). Benefit from real-time account monitoring through tailored trader dashboards',
    },
    {
      ico: <TimeImg />,
      title: 'Analytics',
      main_text:
        'Take advantage of multiple reporting options, such as tracking comprehensive transaction history and personalised statistical insights in order to support optimisation for profitability and secure the business from potential abusers',
    },
    {
      ico: <ManagementImg />,
      title: 'Branding',
      main_text:
        'Provide your proprietary trading platform with all the necessary elements of brand identity. Logotypes as well as information about the company can be added in the mobile and web version of the platform',
    },
    {
      ico: <MasterImg />,
      title: 'Advanced CRM System',
      main_text:
        'The CRM system seamlessly integrates with the ScaleTrade, creating a powerful solution designed for prop trading firms. It allows scalable customisation of client portals within the trading platform',
    },
  ];
  return (
    <>
      <SEO
        title="ScaleTrade Props Trading"
        description="Follow the top traders and copy their trades with one click in real time. Copy trading, as a type of social trading, is the basis of a special community of traders who can communicate and learn from each other."
        name="ScaleTrade"
        type="article"
        link="/props-trading"
        img={imgCopy}
      />
      <div className="propsPage">
        <div className="container">
          <h1 className="title light-blue-gradient"> Platform Perfectly Suited to Prop Trading</h1>
          <p className="crm__Page-text main_text">
            Start your own proprietary trading business using our award-winning ScaleTrade trading
            platform. Enhance your capabilities with turnkey, cutting-edge solutions developed in
            collaboration with top prop trading technology experts.
          </p>
        </div>
        {arrCopy.map((i, index) => (
          <TwoSides
            title={i.title}
            main_text={i.main_text}
            img={i.img}
            alt={i.alt}
            side={i.side}
            list_arr={i.list}
            last={i.last}
            key={index}
          />
        ))}
        <div className="alfa_block4">
          <div className="platform_centered indent_Title ">
            <h2 className="sub_title light-blue-gradient">Prop Trading features</h2>
          </div>

          <div className="content_features_props">
            {arrFeatures.map((i, index) => (
              <div className="card_feature" key={index + i.title}>
                {i.ico}
                <h3>{i.title}</h3>
                <p className="why_text">{i.main_text}</p>
              </div>
            ))}
          </div>
        </div>
        <LowestBlock />
      </div>
    </>
  );
}
