import React, {useState} from 'react';
import appview from './assets/limage.webp';
import browser from './assets/rimage.webp';
import {ReactComponent as SliderArrow} from './assets/slidearrow.svg';
import {useSelector} from 'react-redux';
import useWidthHook from '../../../../components/Hooks/WidthState.jsx';

export default function SliderApp() {
  let WidthWindow = useWidthHook();
  let SliderWidth = 0;
  if (WidthWindow >= 1920) {
    SliderWidth = '1276px';
  } else if (WidthWindow >= 1440) {
    SliderWidth = '1235px';
  } else if (WidthWindow >= 1024) {
    SliderWidth = '820px';
  } else if (WidthWindow >= 768) {
    SliderWidth = '735px';
  } else if (WidthWindow >= 425) {
    SliderWidth = '395px';
  } else if (WidthWindow >= 375) {
    SliderWidth = '342px';
  } else if (WidthWindow >= 320) {
    SliderWidth = '287px';
  }
  const a = useSelector((state) => state.langTokens);
  const [width, setWidth] = useState('50%');
  return (
    <div className="">
      <div className="slider_holder">
        <div className="sliders_labels">
          <div className="slider_label" id="label-before-id">
            {a.IN_DESKTOP_TITLE}
          </div>
          <div className="slider_label" id="label-after-id">
            {a.IN_BROWSER_TITLE}
          </div>
        </div>
        <div className="slider">
          <div className="slider_before">
            <img src={appview} alt={'app'} className="slider_image" />
          </div>
          <div className="slider_after" style={{width: width}}>
            <img
              src={browser}
              alt={'browser'}
              className="slider_image"
              style={{width: SliderWidth}}
            />
          </div>
          <input
            type="range"
            className="slider_input"
            min="1"
            max="100"
            onChange={(e) => {
              const sliderPos = e.target.value;
              setWidth(`${sliderPos}%`);
            }}
          ></input>
          <div className="slider_seperator" id="slider_seperator" style={{left: width}}>
            <span className="slider_arrow">
              <SliderArrow />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
