import React from 'react';
import {useSelector} from 'react-redux';

export default function Link(props) {
  const a = useSelector((state) => state.langTokens);
  return (
    <a
      target="_blank"
      href={props.link}
      rel={'noreferrer'}
      download
      className="dowload_link download_btn"
    >
      <span className="link-img">{props.image} </span>
      <span>
        <span className="link-name download_text">{props.for}</span>
        <span className="link-subtitle">Desktop version</span>
      </span>
    </a>
  );
}
