import React from 'react';
import './Card.css';

export default function AthleteCard(props) {
  return (
    <div>
      <div className={props.CardClassName}> </div>
      <p className="athleteCardDescription">{props.AthleteName}</p>
      <p className="athleteCardLink">
        <a href={props.AthleteLink} rel={'noreferrer'} target="_blank" className="colorLink">
          {props.AthleteCaption}
        </a>
      </p>
    </div>
  );
}
