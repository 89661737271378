import React from 'react';
import './walloflove.css';
import Card1 from './assets/card1.webp';
import Card2 from './assets/card2.webp';
import Card3 from './assets/card3.webp';
import Card4 from './assets/card4.webp';
import Card5 from './assets/card5.webp';
import Card6 from './assets/card6.webp';
import Card7 from './assets/card7.webp';
import Card8 from './assets/card8.webp';
import Card9 from './assets/card9.webp';
import Card10 from './assets/card10.webp';
import Card11 from './assets/card11.webp';
import Cyprus from './assets/cyprus.webp';
import WinAward from './assets/winAward.webp';

function Slide(props) {
  return (
    <figure className="slide">
      <a href="/">
        <img src={props.image} alt={'card' + props.caption} className="cardPicture" />
      </a>
      <figcaption className="cardUsername">{props.caption}</figcaption>
    </figure>
  );
}

export default function WallOfLove(props) {
  let arr = [
    {image: Cyprus, caption: '@Cyprus#IFXEXPO'},
    {image: WinAward, caption: '@best#technology#win'},
    {image: Card6, caption: '@scaleTradeDubai'},
    {image: Card7, caption: '@scaleTradeDubai'},
    {image: Card8, caption: '@scaleTradeDubai'},
    {image: Card9, caption: '@scaleTradeDubai'},
    {image: Card1, caption: '@yuriy'},
    {image: Card2, caption: '@george'},
    {image: Card3, caption: '@pattaya'},
    {image: Card4, caption: '@pattaya'},
    {image: Card5, caption: '@pattaya'},
    {image: Card10, caption: '@oleg'},
    {image: Card11, caption: '@batumi'},
    {image: Cyprus, caption: '@Cyprus#IFXEXPO'},
    {image: WinAward, caption: '@best#technology#win'},
    {image: Card6, caption: '@scaleTradeDubai'},
    {image: Card7, caption: '@scaleTradeDubai'},
    {image: Card8, caption: '@scaleTradeDubai'},
    {image: Card9, caption: '@scaleTradeDubai'},
    {image: Card1, caption: '@yuriy'},
    {image: Card2, caption: '@george'},
    {image: Card3, caption: '@pattaya'},
    {image: Card4, caption: '@pattaya'},
    {image: Card5, caption: '@pattaya'},
    {image: Card10, caption: '@oleg'},
    {image: Card11, caption: '@batumi'},
  ];
  let width = 270;
  return (
    <div className="wallOfLoveSlider">
      <div
        className="wallOfLoveSlideTrack wallOfLoveAnimation"
        style={{'--carousel-full-width': `-${width * arr.length - width * 9 - 32}px`}}
      >
        {arr.map((i, index) => (
          <Slide image={i.image} caption={i.caption} key={index} />
        ))}
      </div>
    </div>
  );
}
