import React from 'react';
import BlueBtn from '../../components/BlueBtn/BlueBtn';
import TwoSides from '../../components/twoSidesBlock/TwoSides';
import LowestBlock from '../NewHomePage/components/lowestBlock/lowestBlock';
import './CopyTrading.css';

import {ReactComponent as AutoImg} from './assets/automation.svg';
import {ReactComponent as EaseImg} from './assets/ease.svg';
import {ReactComponent as ManagementImg} from './assets/management.svg';
import {ReactComponent as MasterImg} from './assets/masters.svg';
import {ReactComponent as StatsImg} from './assets/statistics.svg';
import {ReactComponent as TimeImg} from './assets/time.svg';
import SEO from '../../components/SEO/SEO';
import imgCopy from './assets/Copy expert traders.png';
import useWidth from '../../actions/getWidth';

export default function CopyTrading() {
  let width = useWidth();
  let copyTradingTitle, copyTrading2, copyTrading3, copyTrading4;
  if (width >= 768) {
    copyTradingTitle = require('./assets/ST Copy.webp');
    copyTrading2 = require('./assets/Copy page 2.webp');
    copyTrading3 = require('./assets/Copy page 1.webp');
    copyTrading4 = require('./assets/Copy page 4.webp');
  }
  let arrCopy = [
    {
      title: 'Attractive public rating',
      main_text:
        'The rating allows you to follow interesting strategies online. The rating supports filtering systems based on various parameters and a mini ROI chart.',
      img: copyTrading4,
      alt: 'Attractive public rating',
      side: 'right',
    },
    {
      title: 'Convenience of social trading management',
      main_text:
        'A clear and functional back office allows you to monitor the copy trading system online, track the most effective traders, and manage platform settings',
      list: [
        {item: 'Profitability (ROI) in percentage and money'},
        {item: 'Convenient backoffice'},
        {item: 'Detailed statistics on orders'},
        {item: 'Unique parameter Stability factor'},
      ],
      img: copyTrading3,
      alt: 'Convenience of social trading management',
      side: 'left',
    },
    {
      title: 'Reaching a new level in the trading business',
      list: [
        {item: `"Easy to use" interface`},
        {item: 'Suitable for all types of markets'},
        {item: 'Flexible parameters configuration'},
        {item: 'Instant profit sharing'},
      ],
      img: copyTrading2,
      side: 'right',
      alt: 'Reaching a new level in the trading business',
      last: true,
    },
  ];

  let arrFeatures = [
    {
      ico: <EaseImg />,
      title: 'Ease of use',
      main_text: 'Follow leading crypto traders and copy their trades with one click',
    },
    {
      ico: <StatsImg />,
      title: 'Detailed statistics',
      main_text: 'Unique profit parameters and stability factor',
    },
    {
      ico: <AutoImg />,
      title: 'Process automation',
      main_text: 'Set the settings once and simply follow the master strategies',
    },
    {
      ico: <TimeImg />,
      title: 'Save time',
      main_text:
        'Copy trading saves a lot of time, allowing you to trade like an advanced user right away',
    },
    {
      ico: <ManagementImg />,
      title: 'Convinience management',
      main_text:
        'Transparent back-office system allowing your managers to monitor the entire process',
    },
    {
      ico: <MasterImg />,
      title: 'Visual list of masters',
      main_text: 'Leaderboard of the best trading masters with detailed information on each',
    },
  ];
  return (
    <>
      <SEO
        title="ScaleTrade Copy Trading"
        description="Follow the top traders and copy their trades with one click in real time. Copy trading, as a type of social trading, is the basis of a special community of traders who can communicate and learn from each other."
        name="ScaleTrade"
        type="article"
        link="/copy-trading"
        img={imgCopy}
      />
      <div className="copyPage">
        <div className="container">
          <h1 className="title light-blue-gradient"> ScaleTrade Copy Trading</h1>
          <p className="crm__Page-text main_text">
            System for p2p copy trading of orders with public rating. Applicable for Meta Trader 4,
            Meta Trader 5 and ScaleTrade platform. Optimize your customer flow and convert more
            leads into traders by automating your process
          </p>
          <div className="two_sides row">
            <div className="img_box col-lg-6">
              <img src={copyTradingTitle} alt={'ScaleTrade Copy Trading img'} className="copyImg" />
            </div>
            <div className="col-lg-6">
              <p className="main_text">
                Empower new traders to copy the signals of professionals to increase client
                retention on your trading platform.
              </p>
              <ul className="crm__Page-list main_text">
                <li>Cross-platform support</li>
                <li>Unified eco-system with web trader</li>
                <li>Regulatory сompliant</li>
              </ul>
              <BlueBtn btn_text="Contact Us" click={true} />
            </div>
          </div>
        </div>
        {arrCopy.map((i, index) => (
          <TwoSides
            title={i.title}
            main_text={i.main_text}
            img={i.img}
            alt={i.alt}
            side={i.side}
            list_arr={i.list}
            last={i.last}
            key={index}
          />
        ))}
        <div className="alfa_block4">
          <div className="platform_centered indent_Title ">
            <h2 className="sub_title light-blue-gradient">Copy Trading features</h2>
          </div>

          <div className="content_features_copy">
            {arrFeatures.map((i, index) => (
              <div className="card_feature" key={index + i.title}>
                {i.ico}
                <h3>{i.title}</h3>
                <p className="why_text">{i.main_text}</p>
              </div>
            ))}
          </div>
        </div>
        <LowestBlock />
      </div>
    </>
  );
}
